<template>
  <div class="main">
    <div v-if="isAndroid||isSafari">
      <div>
      <div style="display: flex;align-items: center;">
        <div style="text-align: center;margin-right: 10px;">
          <img style="width: 75px;height: 75px;border-radius: 10px;"

               :src="require('@/assets/icon.png')"
          />
        </div>
        <div>
          <h2 style="margin-top: 0;margin-bottom: 10px;">
            {{ $t('index.name')}}
            <van-icon name="question-o" @click="show=true" v-if="ios"/>
          </h2>
          <div >
            <van-button round  type="primary"  @click="install" v-if="afterDownload">
              <template #icon>
                <img :src="require('@/assets/ios.png')" />
              </template>
              {{
                $t('index.install')
              }}
            </van-button>
            <van-button round  type="primary"   @click="download" v-else>
              <template #icon>
                <img :src="require('@/assets/ios.png')" />
              </template>
              {{ $t('index.download') }}</van-button>

          </div>
          <div >
            <van-button round type="primary" plain   url="./app-release.apk" >
              <template #icon>
                <img :src="require('@/assets/android.png')" />
              </template>
              {{ $t('index.download') }}</van-button>

          </div>
        </div>

      </div>


      <div class="ui-flex" style="margin-top: 10px;border-bottom:1px solid #ebedf0;padding-bottom: 10px;">
        <div>
          <div class="ui-flex">
            <div class="ui-info-title">4.9</div>
            <van-rate v-model="value" allow-half void-icon="star" void-color="#eee"/>
          </div>
          <div class="ui-info-value">587394{{ $t('index.ratings') }}</div>
        </div>
        <div>
          <div class="ui-info-title">18+</div>
          <div class="ui-info-value">{{ $t('index.age') }}</div>
        </div>
        <div style="text-align: center">
          <div>
            <van-button round type="warning">{{ $t('index.complaint') }}</van-button>
          </div>
          <div class="ui-info-value">{{ $t('index.complaint') }}</div>
        </div>

      </div>


      <van-cell :label="$t('index.app.newf')">
        <template #title>
          <h2>{{ $t('index.newFunction') }}</h2>
          <div>{{ $t('index.version') }} 3.2.1</div>
        </template>

      </van-cell>

      <van-cell :label="$t('index.app.info')">
        <template #title>
          <h2> {{ $t('index.introduction') }}</h2>

        </template>

      </van-cell>


      <van-cell class="ui-comments">
        <template #title>
          <h2 style="text-align: center">{{ $t('index.rates') }}</h2>
          <div style="font-size: 40px;line-height:40px;text-align: center">4.9</div>
          <div style="text-align: center">{{ $t('index.outof') }}</div>
        </template>
        <template #default>
          <img :src="require('@/assets/image/star.png')">
          <div>587394{{ $t('index.ratings') }}</div>
        </template>


      </van-cell>

      <div>
        <h2>{{ $t('index.information') }}</h2>
        <van-cell :title="$t('index.seller')" :value="$t('index.company')"/>
        <van-cell :title="$t('index.size')" value="10M"/>
        <van-cell :title="$t('index.languages')" :value="$t('index.langs')"/>
        <van-cell :title="$t('index.age')" :value="$t('index.age18')"/>
        <van-cell title="Copyright" :value="$t('index.copyright')"/>
        <van-cell :title="$t('index.price')" :value="$t('index.free')"/>
        <van-cell>
          <template #title>
            <div style="color: #999999">
              {{ $t('index.appPrivacy') }}
            </div>

          </template>

        </van-cell>

      </div>

      <div style="color: #999999">

        {{ $t('index.disclaim') }}
      </div>
      </div>

    </div>
    <div v-else style="text-align: center">
      <h1 v-if="isNotSafari">{{ $t('index.webTitle2') }}</h1>
      <h1 v-else>{{ $t('index.webTitle') }}</h1>
      <div>
        <van-image
            width="75px"
            fit="cover"
            height="75px"
            :src="require('@/assets/icon.png')"
            style="border-radius: 10px;"
        />

      </div>
      <div>
        <h2> {{$t('index.name')}}</h2>
      </div>
      <div>
       <div> <VueQrcode :value="webUrl" :options="{ width: 300 }"></VueQrcode></div>
       <div> <van-button
            v-clipboard:copy="webUrl"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            type="default">{{ $t('index.copyLink') }}
        </van-button>
       </div>

      </div>
    </div>
    <van-dialog v-model="show" :show-confirm-button="false" close-on-click-overlay>
      <div style="text-align: right;padding:10px;">
        <van-icon name="cross" @click="show=false"/>
      </div>
      <div :style="{display:lang==='en-us'?'block':'none'}">
        <van-steps direction="vertical">
          <van-step>
            <h3>Step 1: Click [Download]</h3>
            <img :src="en1" style="width: 100%">
          </van-step>
          <van-step>
            <h3>Step 2: Click [Allow] to open the configuration description file</h3>
            <img :src="en2" style="width: 100%"></van-step>
          <van-step>
            <h3>
              Step 3: Click [Close] </h3>
            <img :src="en3" style="width: 100%"></van-step>

          <van-step>
            <h3>Step 4: Click [Allow] to open another configuration description file</h3>
            <img :src="en2" style="width: 100%">

          </van-step>
          <van-step>
            <h3>Step 5: Click [Install] to install the app</h3>
            <img :src="en4" style="width: 100%"></van-step>
          <van-step>
            <h3>
              Step 6: Go back to [Desktop], click the icon to open the application</h3>
            <img :src="en5" style="width: 100%">

          </van-step>


        </van-steps>


      </div>
      <div :style="{display:lang==='zh-cn'?'block':'none'}">

        <van-steps direction="vertical">
          <van-step>
            <h3>第一步：点击【下载】</h3>
            <img :src="zh1" style="width: 100%">

          </van-step>
          <van-step>
            <h3>第二步：点击【允许】，打开配置描述文件</h3>
            <img :src="zh2" style="width: 100%">

          </van-step>
          <van-step>
            <h3> 第三步：点击【关闭】 </h3>
            <img :src="zh3" style="width: 100%">

          </van-step>

          <van-step>
            <h3>第四步：点击【允许】，打开另一个配置描述文件</h3>
            <img :src="zh2" style="width: 100%">

          </van-step>

          <van-step>
            <h3>第五步：点击【安装】，安装app</h3>
            <img :src="zh4" style="width: 100%">

          </van-step>
          <van-step>
            <h3>第六步：回到【桌面】，点击图标打开应用</h3>
            <img :src="zh5" style="width: 100%">

          </van-step>
        </van-steps>


      </div>

    </van-dialog>

  </div>
</template>

<script>
import {Button, Cell, Col, Dialog, Icon, Image as VanImage, Notify, Rate, Row, Step, Steps, Toast} from 'vant';
import VueQrcode from '@chenfengyuan/vue-qrcode'
import ua from '@/utils/ua.js'

export default {
  name: 'Home',
  components: {
    VueQrcode,
    [Button.name]: Button,

    [Rate.name]: Rate,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Dialog.Component.name]: Dialog.Component,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [VanImage.name]: VanImage
  },
  computed: {
    isAndroid() {
     return ua.isAndroid()
    },
    isNotSafari(){
      return !ua.isSafari()
    },
    isSafari(){
      return ua.isSafari()
    }
  },
  data() {
    return {
      value: 4.9,
      afterDownload: false,
      show: false,
      lang: 'en-us',
      webUrl: "",
      ios: true,
      version:'',
      count: 0,
      en1: require('@/assets/image/en-us/1.jpg'),
      en2: require('@/assets/image/en-us/2.jpg'),
      en3: require('@/assets/image/en-us/3.jpg'),
      en4: require('@/assets/image/en-us/4.jpg'),
      en5: require('@/assets/image/en-us/5.jpg'),
      zh1: require('@/assets/image/zh-cn/1.jpg'),
      zh2: require('@/assets/image/zh-cn/2.jpg'),
      zh3: require('@/assets/image/zh-cn/3.jpg'),
      zh4: require('@/assets/image/zh-cn/4.jpg'),
      zh5: require('@/assets/image/zh-cn/5.jpg'),
    }
  },
  created() {
    this.lang = this.$i18n.locale
    this.webUrl = location.href
    this.ios = ua.isIOS()
    this.version=ua.version()
    console.log(navigator.userAgent)
    console.log("version:"+this.version)
    // this.preload()
  },
  methods: {
    onCopy: function () {
      Toast.success(this.$t('index.copySuccess'))
    },
    onError: function () {
      Toast.fail(this.$t('index.copyFail'))
    },
    preload: function () {
      let imgs = [
        this.en1,
        this.en2,
        this.en3,
        this.en4,
        this.en5,
        this.zh1,
        this.zh2,
        this.zh3,
        this.zh4,
        this.zh5
      ]

      for (let img of imgs) {
        let image = new Image()
        image.src = img
        image.onload = () => {
          this.count++
        }
      }
    },

    download() {
        let thiz = this
        let a = document.createElement('a')
        a.href = './config/signed.mobileconfig'
        a.download = 'signed.mobileconfig'
        a.click();

        if(this.version&&this.version>='Version/17'){
          Notify({type:'warning',message:this.$t('index.systemTip'),duration:0,onClick:()=>{
            Notify.clear()

            }})
          return
        }

        this.afterDownload = true
        setTimeout(function () {
          thiz.install()
        }, 4000)



    },
    install() {
      let a = document.createElement('a')
      a.href = "./config/app.mobileprovision"
      a.download = 'app.mobileprovision'
      a.click();
      this.afterDownload = false
      //this.show = true
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ui-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ui-flex-start {
  display: flex;
  flex-direction: row;

}

.van-button {
  height: 26px;
  width: 100px;
  margin-bottom: 10px;

}
.van-button img{
  height: 20px;
}
.main {
  padding: 20px;
}

.van-cell__title {
  text-align: left;
}

.van-cell {
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}

.ui-info-title {
  font-weight: bold;
  font-size: 16px;
  color: #8C8E92;
}

.ui-info-value {
  font-size: 16px;
  color: #8C8E92;
}

.ui-comments {
  justify-content: center;
  align-items: center;
}

.ui-comments .van-cell__value {
  flex: 2;
  text-align: right;
}

h2 {
  font-size: 18px;
}

.van-dialog {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  border-radius: 0;
}


</style>
