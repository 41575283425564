const zh = {
    index: {
        install: '安装',
        name: "CMCOXEL",
        webTitle: '请使用手机扫描二维码下载',
        webTitle2:'请在Safari浏览器中打开',
        download: '下载',
        ratings: '评分',
        age: '年龄',
        complaint: '应用投诉',
        newFunction: '新功能',
        version: '版本',
        introduction: '应用介绍',
        rates: '评论及评分',
        outof: '满分5分',
        information: '信息',
        seller: '供应商',
        size: '大小',
        compatibility: '兼容性',
        languages: '语言',
        ageRating: '年龄分级',
        price: '价格',
        free: '免费',
        age18: '限18岁以上',
        langs: '中文和英文',
        ios: '需要IOS 9.0或更高版本',
        appPrivacy: '隐私政策',
        disclaim: '免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。',
        app: {
            info: '欢迎来到 CMCOXEL Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。 作为世界领先的加密货币交易所，CMCOXEL以率先推出优质项目和模因币而闻名, CMCOXEL Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。',
            newf: '此版本包括错误修复和性能改进'

        },
        company: 'CMCOXEL',
        copyright: '© 2022 CMCOXEL Global Ltd.',
        copyLink:  '复制',
        copySuccess:'复制成功',
        systemTip:'由于17系统限制，描述文件已下载，请手动去【设置】-【通用】-【设备管理】，点击安装'

    }


}

export default zh;
